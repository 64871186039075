<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.DOMAINS')">
          <template v-slot:toolbar>
            <b-button
              variant="primary"
              class="mr-4"
              v-b-modal.addDomain
              v-if="canAdd"
            >
              {{ $t("COMMON.NEW") }}
            </b-button>
            <b-button
              variant="danger"
              v-b-modal.confirm-1
              :disabled="selected.length == 0"
              v-if="canRemove"
            >
              {{ $t("COMMON.DELETE") }}
            </b-button>
            <!-- Add modal -->
            <b-modal
              id="addDomain"
              ref="addDomain"
              title="Add Domain"
              class="chPass-modal"
            >
              <b-container fluid>
                <b-row class="my-4">
                  <b-col sm="5">
                    <label for="newDomain">{{
                      $t("DOMAINS.NEW_DOMAIN")
                    }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="newDomain"
                      v-model="$v.form.newDomain.$model"
                      :state="validateState('newDomain')"
                      :placeholder="$t('DOMAINS.NEW_DOMAIN')"
                      aria-describedby="input-new-domain-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-new-domain-feedback">{{
                      $t("VALIDATION.DOMAIN_FIELD")
                    }}</b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <!---<b-row class="my-4">
                  <b-col sm="5">
                    <label for="newDomain">{{
                      $t("DOMAINS.DOMAIN_TYPE")
                    }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="domainType"
                      value-field="value"
                      text-field="name"
                      :options="domainTypesOptions"
                    ></b-form-select>
                  </b-col>
                </b-row>-->
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="modalClose()">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="createItem()">
                  {{ $t("DOMAINS.ADD_DOMAIN") }}
                </b-button>
              </template>
            </b-modal>
          </template>
          <template v-slot:body>
            <div class="row table-filter">
              <div class="col-sm-6"></div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('COMMON.SEARCH')"
                  class="cus-searchBox"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </div>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="allDomains"
              :single-select="singleSelect"
              :search="search"
              show-select
              item-key="domain"
              class="elevation-1"
              ref="domainTable"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <template v-if="!isMobile">
                    <tr
                      v-for="item in items"
                      :key="item.domain"
                      :class="{ unactiveRow: item.active == 0 }"
                    >
                      <td>
                        <v-checkbox
                          v-model="selected"
                          :value="item"
                          style="margin: 0px; padding: 0px"
                          class="cusTable-checkbox"
                          color="rgba(0,0,0,.54)"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td class="text-start">
                        {{ item.domain }}
                      </td>
                      <td class="text-start">
                          <b-button 
                            pill
                            size="sm"
                            style="width: 100px;"
                            :variant="item.health<3 ? 'danger':item.health<5 ? 'warning':'success'" 
                            @click="showDomainHealth(item)" 
                            v-b-tooltip.hover
                            :title="$t('COMMON.MORE_DETAILS')"
                          >
                            {{ item.healthStatus }}
                          </b-button>
                      </td>
                      <td class="text-start">
			                  <span v-if="item.domaintype != 9">
                          <v-icon
                            medium
                            color="dark"
                            class="mr-4"
                            @click="showItemInfor(item)"
                            v-b-tooltip.hover
                            v-if="canAdd"
                            :title="$t('COMMON.EDIT')"
                          >
                            edit
                          </v-icon>
                          <v-icon
                            medium
                            color="dark"
                            class="mr-4"
                            @click="showItemFooter(item)"
                            v-b-modal.item-footer
                            v-b-tooltip.hover
                            v-if="canAdd"
                            :title="$t('COMMON.FOOTER')"
                          >
                            computer
                          </v-icon>
                          <v-icon
                            medium
                            color="dark"
                            class="mr-4"
                            @click="showDkimSetting(item)"
                            v-b-tooltip.hover
                            v-if="canAdd"
                            :title="$t('DOMAINS.DKIM_SETTINGS')"
                          >
                            settings
                          </v-icon>
			                  </span>
			                  <span v-if="item.domaintype == 9">
                          <v-icon
                            medium
                            color="red"
                            class="mr-4"
                            @click="showDomainVerification(item)"
                            v-b-tooltip.hover
                            :title="$t('DOMAINS.VERIFY')"
                          >
                            domain_verification
                          </v-icon>
                        </span>
                        <v-icon
                          medium
                          color="dark"
                          @click="saveDeltem(item)"
                          v-b-modal.confirm-2
                          v-b-tooltip.hover
                          v-if="canRemove"
                          :title="$t('COMMON.DELETE')"
                        >
                          delete
                        </v-icon>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr
                      v-for="item in items"
                      :key="item.domain"
                      :class="{
                        unactiveRow: item.active == 0,
                      }"
                    >
                      <td class="v-data-table__mobile-row">
                        <div class="v-data-table__mobile-row__header"></div>
                        <v-checkbox
                          v-model="selected"
                          :value="item"
                          style="margin: 0px; padding: 0px"
                          class="cusTable-checkbox"
                          color="rgba(0,0,0,.54)"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td class="v-data-table__mobile-row">
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.DOMAIN") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          {{ item.domain }}
                        </div>
                      </td>
                      <td class="v-data-table__mobile-row">
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("DOMAINS.HEALTH") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
                          <b-button 
                            pill
                            size="sm"
                            style="width: 80px;"
                            :variant="item.health<3 ? 'danger':item.health<5 ? 'warning':'success'" 
                            @click="showDomainHealth(item)" 
                            v-b-tooltip.hover
                            :title="$t('COMMON.MORE_DETAILS')"
                          >
                            {{ item.healthStatus }}
                          </b-button>
                        </div>
                      </td>
                      <td class="v-data-table__mobile-row">
                        <div class="v-data-table__mobile-row__header">
                          {{ $t("COMMON.ACTION") }}
                        </div>
                        <div class="v-data-table__mobile-row__cell">
			                    <span v-if="item.domaintype != 9">
                            <v-icon
                              medium
                              color="dark"
                              class="mr-4"
                              @click="showItemInfor(item)"
                              v-b-tooltip.hover
                              v-if="canAdd"
                              :title="$t('COMMON.EDIT')"
                            >
                              edit
                            </v-icon>
                            <v-icon
                              medium
                              color="dark"
                              class="mr-4"
                              @click="showItemFooter(item)"
                              v-b-modal.item-footer
                              v-b-tooltip.hover
                              v-if="canAdd"
                              :title="$t('COMMON.FOOTER')"
                            >
                              computer
                            </v-icon>
			                    </span>
			                    <span v-if="item.domaintype == 9">
                            <v-icon
                              medium
                              color="red"
                              class="mr-4"
                              @click="showDomainVerification(item)"
                              v-b-tooltip.hover
                              :title="$t('DOMAINS.VERIFY')"
                            >
                              domain_verification 
                            </v-icon>
			                    </span>
                          <v-icon
                            medium
                            color="dark"
                            @click="saveDeltem(item)"
                            v-b-modal.confirm-2
                            v-b-tooltip.hover
                            v-if="canRemove"
                            :title="$t('COMMON.DELETE')"
                          >
                            delete
                          </v-icon>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-data-table>

            <!-- Delete confirm modals -->
            <b-modal
              id="confirm-1"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteItems">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <b-modal
              id="confirm-2"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_ITEM_WARNING", { item: delItem.domain }) }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneItem">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- Domain health modal -->
            <b-modal
              id="modal-health"
              :title="$t('DOMAINS.DOMAIN_HEALTH') + showItem.domain"
              ref="modal-health"
            >
              <b-row class="my-4">
                <b-col sm="5">
                  <b>{{ $t("COMMON.STATUS") }}: </b>
                </b-col>
                <b-col sm="7">
                  <b-badge pill 
                    :variant="showItem.health <= 1 ? 'danger' : 'success'"
                    href="#"
                  >
                    {{ showItem.verification == 1 ? $t("DOMAINS.VERIFYING") : showItem.active == 1 ? $t("COMMON.ENABLED") : $t("COMMON.DISABLED") }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row class="my-4">
                <b-col sm="5">
                  <b>{{ $t("DOMAINS.MX_RECORDS") }}: </b> 
                </b-col>
                <b-col sm="7">
                  <b-badge pill 
                    :variant="showItem.mx_health == 1 ? 'success' : 'danger'"
                    href="#"
                    v-b-tooltip.hover
                    :title="$t('DOMAINS.EXPL_MX')"
                  >
                    {{ showItem.mx_health == 1 ? $t("DOMAINS.FOUND") : $t("DOMAINS.NOT_FOUND") }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row class="my-4" v-if="showItem.caa_block==1">
                <b-col sm="5">
                  <b>{{ $t("DOMAINS.CAA_RECORD") }}: </b> 
                </b-col>
                <b-col sm="7">
                  <b-badge pill 
                    variant="warning"
                    href="#"
                    v-b-tooltip.hover
                    :title="$t('DOMAINS.EXPL_CAA')"
                  >
                    {{ showItem.caa_block == 1 ? $t("DOMAINS.FOUND") : $t("DOMAINS.NOT_FOUND") }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row class="my-4">
                <b-col sm="5">
                  <b>{{ $t("DOMAINS.WEBMAIL_RECORDS") }}: </b> 
                </b-col>
                <b-col sm="7">
                  <b-badge pill 
                    :variant="showItem.webmail_health == 1 ? 'success' : 'warning'"
                    href="#"
                    v-b-tooltip.hover
                    :title="$t('DOMAINS.EXPL_WEBMAIL')"
                  >
                    {{ showItem.webmail_health == 1 ? $t("DOMAINS.FOUND") : $t("DOMAINS.NOT_FOUND") }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row class="my-4">
                <b-col sm="5">
                  <b>{{ $t("DOMAINS.WEBMAIL_SSL") }}: </b> 
                </b-col>
                <b-col sm="7">
                  <b-badge pill 
                    :variant="showItem.webmail_ssl == 1 ? 'success' : 'warning'"
                    href="#"
                    v-b-tooltip.hover
                    :title="$t('DOMAINS.EXPL_WEBMAIL_SSL')"
                  >
                    {{ showItem.webmail_ssl == 1 ? $t("DOMAINS.INSTALLED") : $t("DOMAINS.NOT_INSTALLED") }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row class="my-4">
                <b-col sm="5">
                  <b>{{ $t("DOMAINS.AUTODISCOVER_RECORDS") }}: </b>
                </b-col>
                <b-col sm="7">
                  <b-badge pill 
                    :variant="showItem.autodiscover_health == 1 ? 'success' : 'warning'"
                    href="#"
                    v-b-tooltip.hover
                    :title="$t('DOMAINS.EXPL_AUTODISCOVER')"
                  >
                    {{ showItem.autodiscover_health == 1 ? $t("DOMAINS.FOUND") : $t("DOMAINS.NOT_FOUND") }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row class="my-4">
                <b-col sm="5">
                  <b>{{ $t("DOMAINS.AUTODISCOVER_SSL") }}: </b> 
                </b-col>
                <b-col sm="7">
                  <b-badge pill 
                    :variant="showItem.autodiscover_ssl == 1 ? 'success' : 'warning'"
                    href="#"
                    v-b-tooltip.hover
                    :title="$t('DOMAINS.EXPL_AUTODISCOVER_SSL')"
                  >
                    {{ showItem.autodiscover_ssl == 1 ? $t("DOMAINS.INSTALLED") : $t("DOMAINS.NOT_INSTALLED") }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row class="my-4">
                <b-col sm="5">
                  <b>{{ $t("DOMAINS.SPF_RECORDS") }}: </b> 
                </b-col>
                <b-col sm="7">
                  <b-badge pill 
                    :variant="showItem.spf_health == 1 ? 'success' : 'warning'"
                    href="#"
                    v-b-tooltip.hover
                    :title="$t('DOMAINS.EXPL_SPF')"
                  >
                    {{ showItem.spf_health == 1 ? $t("DOMAINS.FOUND") : $t("DOMAINS.NOT_FOUND") }}
                  </b-badge>
                </b-col>
              </b-row>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('modal-health')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button
                  variant="outline-secondary"
                  v-on:click="getDomainHealth()"
                >
                  {{ $t("COMMON.REFRESH") }}
                </b-button>
                <b-button variant="primary" v-on:click="checkDomainHealthNext">
                  {{ $t("DOMAINS.VERIFY_AGAIN") }}
                </b-button>
              </template>
            </b-modal>
            <!-- Update modal -->
            <b-modal
              :title="$t('COMMON.UPDATE') + updateItem"
              ref="editDomain-modal"
            >
              <b-row class="my-4">
                <b-col sm="5">
                  <label class="mb-0">{{
                    $t("DOMAINS.DOMAIN_TIMEZONE")
                  }}</label>
                </b-col>
                <b-col sm="7">
                  <b-form-select
                    v-model="updateform.timeZone.selected"
                    value-field="timezone"
                    text-field="timezone"
                    :options="updateform.timeZone.data"
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row class="my-4">
                <b-col sm="5">
                  <label class="mb-0">{{
                    $t("COMMON.HOME_COUNTRY")
                  }}</label>
                </b-col>
                <b-col sm="7">
                  <model-list-select :list="updateform.homeCountry.data"
                    v-model="updateform.homeCountry.selected"
                    option-value="value"
                    option-text="text">
                  </model-list-select>
                </b-col>
              </b-row>
              <b-row class="my-4">
                <b-col sm="5">
                  <label class="mb-0">
                    {{ $t("DOMAINS.CATCHALL") }}
                  </label>
                </b-col>
                <b-col sm="7">
                  <b-form-select
                    v-model="updateform.catmail.selected"
                    value-field="value"
                    text-field="name"
                    :options="catmailData"
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row class="my-4">
                <b-col sm="5">
                  <label class="mb-0">
                    {{ $t("COMMON.ACTIVE") }}
                  </label>
                </b-col>
                <b-col sm="7">
                  <b-form-select
                    v-model="updateform.active.selected"
                    value-field="value"
                    text-field="name"
                    :options="activeData"
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row class="my-4">
                <b-col sm="5">
                  <label class="mb-0">
                    {{ $t("DOMAINS.LOCAL_DELIVERY") }}
                  </label>
                </b-col>
                <b-col sm="7">
                  <b-form-select
                    v-model="updateform.localDelivery.selected"
                    value-field="value"
                    text-field="name"
                    :options="localDeliveryData"
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row class="my-4">
                <b-col sm="5">
                  <label class="mb-0">
                    {{ $t("DOMAINS.AUDIT_ENABLED") }}
                  </label>
                </b-col>
                <b-col sm="7">
                  <b-form-select
                    v-model="updateform.audit.selected"
                    value-field="value"
                    text-field="name"
                    :options="auditData"
                  ></b-form-select>
                </b-col>
              </b-row>
              <template v-slot:modal-footer>
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('editDomain-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" @click="updateItemInfor()">
                  {{ $t("COMMON.UPDATE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- DKIM Settings modal -->
            <b-modal
              :title="$t('DOMAINS.DKIM_SETTINGS') + '(' + dkimItem + ')'"
              ref="DKIM-modal"
            >
              <b-row class="my-4">
                <b-col sm="2">
                  <label class="mb-0">
                    {{ $t("DOMAINS.ENABLED") }}
                  </label>
                </b-col>
                <b-col sm="10">
                  <b-form-select
                    v-model="dkimform.enabled.selected"
                    value-field="value"
                    text-field="name"
                    :options="dkimEnabled"
                    @change="changeDkim()"
                  ></b-form-select>
                </b-col>
              </b-row>
              <b-row class="my-4" v-show="dkimform.enabled.selected">
                <b-col sm="2">
                  <label class="mb-0">
                    {{ $t("DOMAINS.HOST") }}
                  </label>
                </b-col>
                <b-col sm="10">
                  <b-form-input v-model="dkimform.host" disabled></b-form-input>
                </b-col>
              </b-row>
              <b-row class="my-4" v-show="dkimform.enabled.selected">
                <b-col sm="2">
                  <label class="mb-0">
                    {{ $t("DOMAINS.KEY") }}
                  </label>
                </b-col>
                <b-col sm="10">
                  <b-form-textarea
                    v-model="dkimform.key"
                    disabled
                    rows="8"
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <template v-slot:modal-footer>
                <b-button
                  variant="danger"
                  v-on:click="confirmModalClose('DKIM-modal')"
                >
                  {{ $t("COMMON.OK") }}
                </b-button>
              </template>
            </b-modal>
            <!-- Verifying Ownership modal -->
            <b-modal
              :title="$t('DOMAINS.VERIFYING') + '(' + verificationItem + ')'"
              ref="VERIFYING-modal"
            >             
              <b-row class="my-4" v-if="verificationMessage">
                <b-col sm="12">
                    <b-alert show variant="danger">{{ verificationMessage }}</b-alert>
                    <br><br>
                </b-col>
              </b-row>
              <b-row class="my-4">
                <b-col sm="12">
                  <label class="mb-0">
                    <strong>{{ $t("DOMAINS.PLEASE_VERIFY") }}</strong>
		    <br><br>
                  </label>
                </b-col>
              </b-row>
              <b-row class="my-4">
		<b-col sm="4">
                  <label class="mb-0">
                    <strong>{{ $t("DOMAINS.HOSTNAME") }}</strong> 
                  </label>
                </b-col>
                <b-col sm="8">
			mx-verification
                </b-col>
              </b-row>
	      <b-row class="my-4">
                <b-col sm="4">
                  <label class="mb-0">
                    <strong>{{ $t("COMMON.TYPE") }}</strong>
                  </label>
                </b-col>
                <b-col sm="8">
                       	TXT 
                </b-col>
              </b-row>
	      <b-row class="my-4" justify="center"> 
                <b-col sm="4">
                  <label class="mb-0">
		    <strong>{{ $t("COMMON.VALUE") }}</strong>
                  </label>
                </b-col>
                <b-col sm="8">
                  <b-form-input v-model="verificationform.text" disabled></b-form-input>
                </b-col>
              </b-row>              
              <template v-slot:modal-footer>
                <b-button
                  variant="danger"
                  v-on:click="confirmModalClose('VERIFYING-modal')"
                >
                  {{ $t("COMMON.OK") }}
                </b-button>
              </template>
            </b-modal>
            <!-- Edit footer modal -->
            <b-modal
              :title="$t('DOMAINS.EDIT_FOOTER') + updateItem"
              ref="editFooter-modal"
            >
              <h6>
                <b-alert show variant="secondary">
                  {{ $t("DOMAINS.FOOTER_DESC") }}
                </b-alert>
              </h6>
              <vue-editor v-model="footerform.editorData"></vue-editor>
              <template v-slot:modal-footer>
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('editFooter-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" @click="updateFooterInfor()">
                  {{ $t("COMMON.UPDATE") }}
                </b-button>
              </template>
            </b-modal>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { required, minLength } from "vuelidate/lib/validators";
import { SETSTATUS } from "@/store/status.module";
import { VueEditor } from "vue2-editor";
// const mustBeDot = value =>
//   value.lastIndexOf(".") > 0 && value.length - value.lastIndexOf(".") != 1;
/**
 * Sample widgets data source
 */

export default {
  mixins: [validationMixin],
  name: "domain",
  components: {
    KTPortlet,
    VueEditor,
  },
  created() {
    this.getAllDomains();
    postRequest({
      action: "getTimezones",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        this.updateform.timeZone.data = res.returndata;
        this.updateform.timeZone.selected = res.returndata[0].timezone;
      }
    });

    postRequest({
      action: "getCountries",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ text: res.returndata }];
        } else {
          for (var key in res.returndata) {
            this.updateform.homeCountry.data.push({
              value: key,
              text: res.returndata[key],
            });
          }
        }
      }
    });

    if (window.innerWidth < 600) this.isMobile = true;
    window.addEventListener("resize", this.resizeWin);

    // for (let i = 1; i < 61; i++) {
    //   this.updateform.expiryPolicy.data.push({ value: i, name: i });
    // }
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWin);
  },

  data: () => ({
    search: "",
    singleSelect: false,
    selected: [],
    form: {
      newDomain: null,
    },
    updateform: {
      timeZone: {
        selected: "",
        data: [],
      },
      homeCountry: {
        selected: "",
        data: [],
      },
      catmail: {
        selected: "bounce-no-mailbox",
      },
      active: {
        selected: 1,
      },
      localDelivery: {
        selected: 1,
      },
      audit: {
        selected: 0,
      },
    },
    dkimform: {
      enabled: {
        selected: 0,
      },
      host: "",
      key: "",
    },
    verificationform: {
      text: "",
      message: "",
    },
    footerform: {
      editorData: "",
    },
    allDomains: [],
    showItem: "",
    delItem: "",
    updateItem: "",
    dkimItem: "",
    verificationItem: "",
    verificationMessage: "",
    isMobile: false,
    domainType: 1
  }),
  validations: {
    form: {
      newDomain: {
        required,
        minLength: minLength(3),
        // mustBeDot
      },
    },
  },
  computed: {
    headers: function () {
      return [
        { text: this.$t("COMMON.DOMAIN"), value: "domain" },
        { text: this.$t("DOMAINS.HEALTH"), value: "health" },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    catmailData: function () {
      return [
        { value: "bounce-no-mailbox", name: this.$t("DOMAINS.NO_CATCHALL") },
        { value: "delete", name: this.$t("DOMAINS.ACCEPT_DELETE") },
      ];
    },
    activeData: function () {
      return [
        { value: 1, name: this.$t("COMMON.YES") },
        { value: 0, name: this.$t("COMMON.NO") },
      ];
    },
    localDeliveryData: function () {
      return [
        { value: 1, name: this.$t("COMMON.YES") },
        { value: 0, name: this.$t("COMMON.NO") },
      ];
    },
    auditData: function () {
      return [
        { value: 1, name: this.$t("COMMON.YES") },
        { value: 0, name: this.$t("COMMON.NO") },
      ];
    },
    dkimEnabled: function () {
      return [
        { value: 1, name: this.$t("COMMON.YES") },
        { value: 0, name: this.$t("COMMON.NO") },
      ];
    },
    domainTypesOptions: function () {
      return [
        { value: 1, name: this.$t("COMMON.REGULAR") },
        { value: 0, name: this.$t("DOMAINS.OEX") },
      ];
    },
    canAdd: function () {
      return this.hasPermission("domains", 5);
    },
    canRemove: function () {
      return this.hasPermission("domains", 7);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.DOMAINS") }]);
    this.$store.dispatch(SETSTATUS, {
      action: "getAdminStats",
      token: localStorage.id_token,
    });
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    /*  -----modal close------- */
    modalClose() {
      this.form.newDomain = null;
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.$refs["addDomain"].hide();
    },
    confirmModalClose(modalItem) {
      this.$refs[modalItem].hide();
    },
    //  resize windows for mobile responsive
    resizeWin() {
      if (window.innerWidth < 600) this.isMobile = true;
      else this.isMobile = false;
    },

    /* ----------get all domain----- */
    getAllDomains() {
      this.allDomains = [];
      this.selected = [];
      postRequest({
        action: "getAllDomains",
        token: localStorage.id_token,
      }).then((res) => {
        if (res) {
          if (!res.returncode) {
            res.returndata = [{ domain: res.returndata }];
          } else {
            let i;
            for (i in res.returndata) {
              if (res.returndata[i].isauditdomain != 1) {
                let obj = res.returndata[i];
                obj.health = this.getDomainHealthScore(obj);
                obj.healthStatus = obj.health < 3 ? this.$t("DOMAINS.FAIL") : obj.health < 5 ? this.$t("COMMON.WARNING"):this.$t("DOMAINS.OK");
                this.allDomains.push(obj);
              }
            }
          }
        }
      });
    },

    getDomainHealthScore(domain){
      if (domain.verification == 1)
        return 0; // red
      if (domain.active != 1)
        return 1; // red
      if (domain.mx_health != 1)
        return 2; // red
      if(domain.caa_block == 1)
        return 3; // yellow
      if (domain.webmail_health != 1 || domain.webmail_ssl != 1 ||
          domain.autodiscover_health != 1 || domain.autodiscover_ssl != 1 ||
          domain.spf_health != 1)
        return 4; // yellow
      return 5; // green
    },
    /* ------create item---- */
    createItem() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      let action = "addDomain";

      if (this.domainType == 0) {
        action = "addOEXDomain";
      }

      postRequest({
        action: action,
        token: localStorage.id_token,
        newdomain: this.form.newDomain,
      }).then((res) => {
        this.modalClose();
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          this.getAllDomains();
          this.$store.dispatch(SETSTATUS, {
            action: "getAdminStats",
            token: localStorage.id_token,
          });
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    /* -------delete item----- */
    saveDeltem(item) {
      this.delItem = item;
    },
    deleteOneItem() {
      const item = this.delItem;
      postRequest({
        action: "removeDomain",
        token: localStorage.id_token,
        domain: item.domain,
      }).then((res) => {
        this.confirmModalClose("delConfirm-modal");
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.$store.dispatch(SETSTATUS, {
          action: "getAdminStats",
          token: localStorage.id_token,
        });
        this.getAllDomains();
      });
    },
    deleteItems() {
      if (this.selected.length > 0) {
        this.selected.forEach((el) => {
          postRequest({
            action: "removeDomain",
            token: localStorage.id_token,
            domain: el.domain,
          }).then((res) => {
            this.confirmModalClose("delConfirm-modal");
            if (res.returncode) {
              this.makeToastVariant("success", res.returndata);
            } else {
              this.makeToastVariant("danger", res.returndata);
            }
            this.$store.dispatch(SETSTATUS, {
              action: "getAdminStats",
              token: localStorage.id_token,
            });
            for (let i = 0; i < this.allDomains.length; i++) {
              if (this.allDomains[i].id == el.id) {
                this.allDomains.splice(i, 1);
              }
            }
          });
        });
      }
    },

    /* -------domain health------ */
    showDomainHealth(item) {
      this.showItem = item;
      this.getDomainHealth();
      this.$refs["modal-health"].show();
    },
    getDomainHealth() {
      const showItem = this.showItem;
      postRequest({
        action: "getDomainHealth",
        token: localStorage.id_token,
        domain: this.showItem.domain,
      }).then((res) => {
        if (res.returncode) {
          showItem.mx_health = res.returndata.mx_health;
          showItem.check_next = res.returndata.check_next;
          showItem.caa_block = res.returndata.caa_block;
          showItem.webmail_health = res.returndata.webmail_health;
          showItem.webmail_ssl = res.returndata.webmail_ssl;
          showItem.autodiscover_health = res.returndata.autodiscover_health;
          showItem.autodiscover_ssl = res.returndata.autodiscover_ssl;
          showItem.spf_health = res.returndata.spf_health;
          
          showItem.health = this.getDomainHealthScore(showItem);
          showItem.healthStatus = showItem.health < 3 ? this.$t("DOMAINS.FAIL") : showItem.health < 5 ? this.$t("COMMON.WARNING"):this.$t("DOMAINS.OK");
          if (this.showItem.domain == showItem.domain)
            this.showItem = showItem;
        }
      });
    },
    checkDomainHealthNext() {
      if (this.showItem.check_next == 0) {
        postRequest({
          action: "dnsCheckNext",
          token: localStorage.id_token,
          domain: this.showItem.domain,
        }).then((res) => {
          this.confirmModalClose("modal-health");
          if (res.returncode) {
            this.makeToastVariant("success", res.returndata);
            this.makeToastVariant("success", this.$t("DOMAINS.CHECK_HEALTH_INFO"));
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      } else {
        this.makeToastVariant("success", this.$t("DOMAINS.CHECK_HEALTH_INFO"));
        this.confirmModalClose("modal-health");
      }
    },

    /* ------edit item----- */
    async showItemInfor(item) {
      await postRequest({
        action: "getDomainInfo",
        token: localStorage.id_token,
        domain: item.domain,
      }).then((res) => {
        this.updateItem = item.domain;
        this.updateform.timeZone.selected = res.returndata.dtzone;
        this.updateform.homeCountry.selected = res.returndata.homecountry;
        this.updateform.catmail.selected = res.returndata.catchall;
        this.updateform.active.selected = res.returndata.active;
        this.updateform.localDelivery.selected = res.returndata.localdelivery;
        this.updateform.audit.selected = res.returndata.audit_enabled;
      });
      this.catmailData.splice(2);
      await postRequest({
        action: "getAllUsersDomain",
        token: localStorage.id_token,
        domain: item.domain,
        offset: 0,
        limit: 0,
        sorting: 0,
        slicing: 0,
        getOTPAndToken: 0,
        showQuota: 0,
      }).then((res) => {
        if (res.returncode) {
          res.returndata.forEach((el) => {
            this.catmailData.push({
              value: el.username,
              name: el.username,
            });
          });
        }
      });
      this.$refs["editDomain-modal"].show();
    },

    updateItemInfor() {
      const updateItem = this.updateItem;
      const acitveState = this.updateform.active.selected;
      postRequest({
        action: "updateDomain",
        domain: this.updateItem,
        token: localStorage.id_token,
        editdtzone: this.updateform.timeZone.selected,
        homecountry: this.updateform.homeCountry.selected,
        editcatchall: this.updateform.catmail.selected,
        editdomainactive: this.updateform.active.selected,
        editlocaldelivery: this.updateform.localDelivery.selected,
        editauditenabled: this.updateform.audit.selected,
      }).then((res) => {
        this.allDomains.forEach((el) => {
          if (el.domain == updateItem) {
            el.active = acitveState;
          }
        });
        this.confirmModalClose("editDomain-modal");
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    /* -------edit footer------ */
    showItemFooter(item) {
      postRequest({
        action: "getFooterDomain",
        token: localStorage.id_token,
        domain: item.domain,
      }).then((res) => {
        this.$refs["editFooter-modal"].show();
        this.updateItem = item.domain;
        this.footerform.editorData = res.returndata;
      });
    },

    updateFooterInfor() {
      postRequest({
        action: "setFooterDomain",
        footertxt: this.footerform.editorData,
        token: localStorage.id_token,
        domain: this.updateItem,
      }).then((res) => {
        this.confirmModalClose("editFooter-modal");
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    /* -------Domain Verification */
    showDomainVerification(item) {
      this.verificationItem = item.domain;
      postRequest({
        action: "getDomainVerification",
        token: localStorage.id_token,
        domain: item.domain,
      }).then((res) => {
        if (res.returncode) {
	        this.verificationMessage=res.message;
          this.verificationform.text = res.returndata;
          this.$refs["VERIFYING-modal"].show();
        }
      });
    },

    /* -------DKIM Settings------ */
    showDkimSetting(item) {
      this.dkimItem = item.domain;
      postRequest({
        action: "getDKIM",
        token: localStorage.id_token,
        domain: item.domain,
      }).then((res) => {
        if (res.returncode) {
          this.dkimform.enabled.selected = res.returndata.dkim_enabled * 1;
          this.dkimform.host = res.returndata.dkim_host;
          this.dkimform.key = res.returndata.dkim_key;
          this.$refs["DKIM-modal"].show();
        }
      });
    },

    changeDkim() {
      if (this.dkimform.enabled.selected) {
        postRequest({
          action: "enableDKIM",
          token: localStorage.id_token,
          domain: this.dkimItem,
        }).then((res) => {
          if (res.returncode) {
            this.makeToastVariant("success", res.returndata);
            this.dkimform.host = res.dkimhost;
            this.dkimform.key = res.dkimkey;
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      } else {
        postRequest({
          action: "disableDKIM",
          token: localStorage.id_token,
          domain: this.dkimItem,
        }).then((res) => {
          if (res.returncode) {
            this.makeToastVariant("success", res.returndata);
            this.dkimform.host = "";
            this.dkimform.key = "";
          } else {
            this.makeToastVariant("danger", res.returndata);
          }
        });
      }
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>
<style>
:root {
  --ck-z-default: 100 !important;
  --ck-z-modal: calc(var(--ck-z-default) + 999) !important;
}
</style>
